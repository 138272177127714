import { userApi } from "../userApi";

export const extendedApiSlice = userApi.injectEndpoints({
  endpoints: (builder) => ({
    advisorFullProfile: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/getAdvisorFullProfile",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
    updateAdvisorLiveStatus: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/updateLiveStatus",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
    updateRushStatus: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/updateRushStatus",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),

    updateCallStatus: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/updateCallStatus",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),

    updateAdvisorStatus: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/updateStatus",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
    getAdvisorLiveRate: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/getAdvisorSpecificLiveChatRate",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
    updateAdvisorLiveRate: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/updateLiveChatRate",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
    acknowledgeAdminNotification: builder.mutation({
      query: (formData) => ({
        url: "Advisor_API/acknowledgeAdminNotification",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
    messageRefund: builder.mutation({
      query: (formData) => ({
        url: "Transaction_API/messageRefund",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Advisor"],
    }),
  }),
});

export const {
  useAdvisorFullProfileMutation,
  useUpdateAdvisorLiveStatusMutation,
  useUpdateRushStatusMutation,
  useUpdateAdvisorStatusMutation,
  useGetAdvisorLiveRateMutation,
  useUpdateAdvisorLiveRateMutation,
  useAcknowledgeAdminNotificationMutation,
  useMessageRefundMutation,
  useUpdateCallStatusMutation,
} = extendedApiSlice;
