import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdCall, MdCallEnd } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { useUpdateChatRoomMutation } from "../../app/api/chat";
import { generateDefaultFormData } from "../../utils/helper/getUpdateRoomData";
import { setIsCallAccepted } from "./modalSlice";
import { Howl } from "howler";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader";

const CallingModal = () => {
  const dispatch = useDispatch();
  const [sound, setSound] = useState(null);
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);
  const { isCallingModalOpen, isCallAccepted } = useSelector(
    (state) => state.modal
  );
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const { userSessionData } = useSelector((state) => state.chat);
  const [updateChatRoom] = useUpdateChatRoomMutation();
  const shakeAnimation = {
    scale: [1, 1.1, 1],
    rotate: [0, 10, -10, 10, -10, 0],
    transition: { duration: 0.5, repeat: Infinity, ease: "easeInOut" },
  };

  const handleCallResponse = async (res) => {
    dispatch(setIsCallAccepted(true));
    const activity = res === "accept" ? "live_chat_accept" : "live_chat_reject";
    const formData = generateDefaultFormData(
      userSessionData?.session_id,
      activity,
      advisorLoginResponse?.user?.id
    );
    const response = await updateChatRoom(formData);
    if (sound) {
      sound.stop();
    }
  };

  const startSound = () => {
    if (!isSoundPlaying) {
      const newSound = new Howl({ src: ["/ringtone.mp3"], loop: true });
      newSound.play();
      setSound(newSound);
      setIsSoundPlaying(true);
    }
  };

  useEffect(() => {
    if (isCallingModalOpen) {
      startSound();
    } else {
      if (sound) {
        sound.stop();
        setIsSoundPlaying(false);
      }
    }
  }, [isCallingModalOpen, sound]);

  return (
    <AnimatePresence>
      {isCallingModalOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999] backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="flex flex-col bg-white min-w-80 w-fit py-8 px-12 relative rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div>
              <h6 className="mb-4 text-center text-xl">
                {`${userSessionData?.client_displayname} is calling`}
              </h6>
              {isCallAccepted ? (
                <div className="flex justify-center">
                  <Loader height={50} width={50} color={"#3A86F4"} />
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <motion.button
                    className="bg-green-600 p-3 rounded-full shadow-xl"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => handleCallResponse("accept")}
                  >
                    <motion.div animate={shakeAnimation}>
                      <MdCall className="text-white text-5xl" />
                    </motion.div>
                  </motion.button>
                  <motion.button
                    className="bg-red-600 p-3 rounded-full shadow-xl"
                    onClick={() => handleCallResponse("reject")}
                    whileHover={{ scale: 1.1 }}
                  >
                    <MdCallEnd className="text-white text-5xl" />
                  </motion.button>
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CallingModal;
