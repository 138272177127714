import React, { useCallback, useEffect, useRef, useState } from "react";
import Conversation from "./Conversation";
import Messages from "./Messages";
import { useDispatch, useSelector } from "react-redux";
import { TbLogout } from "react-icons/tb";
import { FiInbox } from "react-icons/fi";
import { PiFolderSimpleUserBold } from "react-icons/pi";
import { FaLevelDownAlt } from "react-icons/fa";
import { userApi } from "../app/userApi";
import { useFetchChatLogs } from "../hooks/useFetchChatLogs";
import { getUserData } from "../utils/helper/getUserId";
import {
  useAdvisorFullProfileMutation,
  useGetAdvisorLiveRateMutation,
  useUpdateAdvisorLiveStatusMutation,
} from "../app/api/advisor";
import {
  setAdvisorLiveStatus,
  setAdvisorRushStatus,
  setAdvisorStatus,
  setGetAdvisorLiveRate,
  setGetadvisorSelectedRate,
} from "../features/advisor/advisorSlice";
import Loader from "./Loader";
import useLogout from "../hooks/useLogout";
import usePubNubData from "../hooks/usePubnubData";
import {
  setGetLiveTemplate,
  setGetFollowupTemplate,
  setGetTextTemplate,
  setIsInboxIsClicked,
  setIsReEngagementClicked,
  setIsMessageRepliedSuccessfully,
  setChatHeadPaginationAdding,
  setChatHeadPagination,
} from "../features/chat/chatSlice";
import { useAdvisorStatus } from "../hooks/useAdvisorStatus";

const Chat = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [getBottom, setGetBottom] = useState(false);
  const [isGetChatHeadIsLoading, setIsGetChatHeadIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const bottomElementRef = useRef(null);
  const loadingRef = useRef(null);
  const [advisorFullProfile, { error }] = useAdvisorFullProfileMutation();
  const [updateAdvisorLiveStatus] = useUpdateAdvisorLiveStatusMutation();
  const [getAdvisorLiveRate, { error: isError }] =
    useGetAdvisorLiveRateMutation();
  const { handleLogout } = useLogout();
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const { isInboxIsClicked, isReEngagementClicked } = useSelector(
    (state) => state.chat
  );
  const { pubnubAdvisor } = usePubNubData();

  const user = getUserData(advisorLoginResponse);
  const { s3_profile_picture, username, email, id } = user || {};
  const { setOfflineStatus, setOfflineFastStatus, setOfflineCallStatus } =
    useAdvisorStatus(id);

  const { userSessionData } = useFetchChatLogs(id);

  const getAdvisorFullProfile = async () => {
    try {
      const formAdvisorData = new FormData();
      formAdvisorData.append("id", id);
      const result = await advisorFullProfile(formAdvisorData).unwrap();

      const advisorProfileData = Object.values(result?.message);
      const getAdvisorTemplate = advisorProfileData?.[0]?.templates;

      const advisorProfileLiveStatus = advisorProfileData?.[0]?.live_status;
      const advisorProfileRushStatus = advisorProfileData?.[0]?.rush_status;
      const advisorProfileStatus = advisorProfileData?.[0]?.status;
      const advisorProfileLiveRate = advisorProfileData?.[0]?.live_rate;

      dispatch(setGetLiveTemplate(getAdvisorTemplate?.live));
      dispatch(setGetTextTemplate(getAdvisorTemplate?.text));
      dispatch(setGetFollowupTemplate(getAdvisorTemplate?.followup));

      advisorProfileLiveStatus == "1" || advisorProfileLiveStatus == "2"
        ? dispatch(setAdvisorLiveStatus(true))
        : dispatch(setAdvisorLiveStatus(false));

      advisorProfileRushStatus == "1"
        ? dispatch(setAdvisorRushStatus(true))
        : dispatch(setAdvisorRushStatus(false));

      advisorProfileStatus == "1"
        ? dispatch(setAdvisorStatus(true))
        : dispatch(setAdvisorStatus(false));

      dispatch(setGetadvisorSelectedRate(advisorProfileLiveRate));
    } catch (err) {
      if (err?.status === 401) {
        handleLogout();
      }
    }
  };

  const getAdvisorLiveRateData = async () => {
    try {
      const formAdvisorLiveRateData = new FormData();
      formAdvisorLiveRateData.append("id", id);
      formAdvisorLiveRateData.append("user_id", id);
      formAdvisorLiveRateData.append("user_type", "advisor");
      const result = await getAdvisorLiveRate(formAdvisorLiveRateData).unwrap();

      if (result?.result == "1") {
        dispatch(setGetAdvisorLiveRate(result?.live_rates_array));
      }
    } catch (err) {
      if (err?.status === 401) {
        handleLogout();
      }
    }
  };

  useEffect(() => {
    getAdvisorFullProfile();
    getAdvisorLiveRateData();
  }, [user]);

  // const offlineAdvisorLiveStatus = async () => {
  //   const formAdvisorStatusUpdate = new FormData();
  //   formAdvisorStatusUpdate.append("id", id);
  //   formAdvisorStatusUpdate.append("live_status", 0);
  //   formAdvisorStatusUpdate.append("device_type", "web");
  //   formAdvisorStatusUpdate.append("user_id", id);
  //   formAdvisorStatusUpdate.append("user_type", "advisor");
  //   await updateAdvisorLiveStatus(formAdvisorStatusUpdate).unwrap();
  // };

  const offlineAdvisorLiveStatus = async () => {
    try {
      await Promise.all([
        setOfflineCallStatus(),
        setOfflineStatus(),
        setOfflineFastStatus(),
      ]).catch((error) => {
        // This catch is redundant
        console.error("Error updating advisor status:", error);
      });
    } catch (error) {
      console.error("Failed to update advisor statuses:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (bottomElementRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          bottomElementRef.current;
        if (
          scrollHeight - scrollTop === clientHeight &&
          (userSessionData?.session_status !== "S" ||
            userSessionData?.session_status !== "A" ||
            userSessionData?.session_status == "X") &&
          !isGetChatHeadIsLoading
        ) {
          if (isInboxIsClicked) {
            setIsGetChatHeadIsLoading(true);
            dispatch(setChatHeadPaginationAdding(1));
          }
        }
      }
    };

    const element = bottomElementRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div>
      <div className="flex bg-gray-100">
        <div className="w-[430px] h-screen  bg-gray-100  hidden md:block">
          <div className="h-full overflow-y-auto ">
            <div className=" flex justify-between items-center text-xl font-extrabold text-gray-600 dark:text-gray-200 p-5">
              <div className="h-10 w-10 flex items-center gap-4 rounded-full shadow-lg">
                <img
                  src={s3_profile_picture}
                  className="w-full h-full rounded-full object-cover"
                />
                <div>
                  <h4 className="text-black text-base font-medium">
                    {username}
                  </h4>
                  <p className="text-black text-sm font-normal">{email}</p>
                </div>
              </div>
              {userSessionData.status !== "S" && (
                <TbLogout
                  className="text-black text-2xl cursor-pointer"
                  onClick={() => {
                    handleLogout();
                    offlineAdvisorLiveStatus();
                  }}
                />
              )}
            </div>

            {/* <div className="flex-grow border-t bg-gray-100 opacity-50 mb-3"></div> */}
            <div className="h-[83%] m-1 flex items-start">
              {userSessionData.status !== "S" && (
                <div className=" flex flex-col ga-4 bg-white rounded-lg shadow-lg">
                  <FiInbox
                    onClick={() => {
                      dispatch(setIsInboxIsClicked(true));
                      dispatch(setIsReEngagementClicked(false));
                      dispatch(setIsMessageRepliedSuccessfully(true));
                    }}
                    className={`cursor-pointer p-2 text-4xl rounded-lg ${
                      isInboxIsClicked ? `bg-[#3A86F4] text-white` : ""
                    }`}
                  />
                  <PiFolderSimpleUserBold
                    onClick={() => {
                      dispatch(setIsInboxIsClicked(false));
                      dispatch(setIsReEngagementClicked(true));
                      dispatch(setChatHeadPagination(0));
                    }}
                    className={`cursor-pointer p-2 text-4xl rounded-lg ${
                      isReEngagementClicked ? `bg-[#3A86F4] text-white` : ""
                    }`}
                  />
                </div>
              )}

              <div
                className="bg-white h-full m-4 mt-0 ml-1 overflow-y-auto scroll-smooth	flex flex-col rounded-3xl py-4 shadow-lg relative"
                style={{
                  width: "-webkit-fill-available",
                }}
                ref={bottomElementRef}
              >
                <div className="bg-gray-100 flex justify-center items-center pr-3 text-gray-400 p-2 rounded-3xl w-[90%] m-auto ">
                  <input
                    className="input text-gray-700 bg-gray-100 pl-3  text-sm  focus:outline-none   w-full rounded-l-md"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                </div>
                {/* <div className="flex-grow border-t bg-gray-100 opacity-50 mt-3 "></div> */}

                <Conversation
                  searchTerm={searchTerm}
                  page={page}
                  setIsGetChatHeadIsLoading={setIsGetChatHeadIsLoading}
                />
              </div>

              {isGetChatHeadIsLoading && isInboxIsClicked && (
                <div className="fixed bottom-14 left-[210px]">
                  <Loader width={30} height={30} color={"#3A86F4"} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="flex-grow  h-screen p-2 rounded-md"
          style={{ width: "calc(100% - 380px)" }}
        >
          <Messages />
        </div>
      </div>
    </div>
  );
};

export default Chat;
