import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFreeTimer,
  setPaidTimer,
  setSessionTimer,
} from "../features/chat/chatSlice";
import {
  setClientResponseFromUpdateChatRoom,
  setIsWaitingForClientRecharge,
} from "../features/modal/modalSlice";
import useAllModalTimerHandler from "./useAllModalTimerHandler";
import { generateDefaultFormData } from "../utils/helper/getUpdateRoomData";
import { useUpdateChatRoomMutation } from "../app/api/chat";

const timerWorkerCode = `
  class TimerManager {
    constructor() {
      this.timers = new Map();
      this.intervals = new Map();
      this.initialValues = new Map();
      this.nextTimer = null;  // Store next timer to start
    }

    startTimer(type, seconds, initialValue = seconds, nextTimerConfig = null) {
      this.stopTimer(type);
      
      this.initialValues.set(type, initialValue);
      this.timers.set(type, seconds);
      
      // Store next timer configuration if provided
      this.nextTimer = nextTimerConfig;
      
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        const currentValue = Math.max(0, seconds - elapsedSeconds);
        this.timers.set(type, currentValue);

        if (currentValue <= 0) {
          this.stopTimer(type);
          
          // Start next timer immediately if configured
          if (this.nextTimer) {
            const { type: nextType, seconds: nextSeconds, initialValue: nextInitial } = this.nextTimer;
            this.startTimer(nextType, nextSeconds, nextInitial);
            this.nextTimer = null;  // Clear next timer config
          }
          
          self.postMessage({ 
            type, 
            value: 0, 
            status: 'completed',
            initialValue: this.initialValues.get(type)
          });
          return;
        }
        
        self.postMessage({ 
          type, 
          value: currentValue, 
          status: 'running',
          initialValue: this.initialValues.get(type)
        });
      }, 200);  // More frequent updates for smoother transitions
      
      this.intervals.set(type, interval);
    }

    stopTimer(type) {
      if (this.intervals.has(type)) {
        clearInterval(this.intervals.get(type));
        this.intervals.delete(type);
      }
      this.timers.delete(type);
      this.initialValues.delete(type);
    }

    stopAllTimers() {
      this.nextTimer = null;
      for (const type of this.intervals.keys()) {
        this.stopTimer(type);
      }
    }
  }

  const timerManager = new TimerManager();

  self.onmessage = function(e) {
    const { command, type, seconds, initialValue, nextTimer } = e.data;
    
    switch (command) {
      case 'start':
        timerManager.startTimer(type, seconds, initialValue, nextTimer);
        break;
      case 'stop':
        if (type) {
          timerManager.stopTimer(type);
        } else {
          timerManager.stopAllTimers();
        }
        break;
      default:
        console.error('Unknown command:', command);
    }
  };
`;

const useHandleTimer = () => {
  const { startAllModalHandlerTimer } = useAllModalTimerHandler();
  const [updateChatRoom] = useUpdateChatRoomMutation();
  const { advisorLoginResponse } = useSelector((state) => state.login);
  const { userSessionData } = useSelector((state) => state.chat);

  const workerRef = useRef(null);
  const paidSecondsRef = useRef(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const blob = new Blob([timerWorkerCode], {
      type: "application/javascript",
    });
    const workerUrl = URL.createObjectURL(blob);
    workerRef.current = new Worker(workerUrl);
    URL.revokeObjectURL(workerUrl);

    workerRef.current.onmessage = (event) => {
      const { type, value, status } = event.data;

      switch (type) {
        case "session":
          if (status === "completed") {
            handleSessionComplete();
          } else {
            dispatch(setSessionTimer(value));
          }
          break;

        case "free":
          dispatch(setFreeTimer(value));
          break;

        case "paid":
          dispatch(setPaidTimer(value));
          break;
      }
    };

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    };
  }, [dispatch]);

  const handleSessionComplete = async () => {
    stopTimerForChat();
    dispatch(setSessionTimer(0));
    dispatch(setFreeTimer(0));
    dispatch(setPaidTimer(0));
    dispatch(
      setClientResponseFromUpdateChatRoom(
        "waiting for client to refill his live chat"
      )
    );
    dispatch(setIsWaitingForClientRecharge(true));

    startAllModalHandlerTimer("waitingForCreditsToRefil", 120, async () => {
      dispatch(setIsWaitingForClientRecharge(false));
      const formData = generateDefaultFormData(
        userSessionData?.session_id,
        "live_chat_end",
        advisorLoginResponse?.user?.id
      );
      await updateChatRoom(formData);
    });
  };

  const startTimerForChat = (totalSeconds, freeSeconds, paidSeconds) => {
    if (
      !workerRef.current ||
      typeof totalSeconds !== "number" ||
      isNaN(totalSeconds)
    ) {
      console.error("Invalid timer configuration or worker not initialized");
      return;
    }

    // Store paid seconds for later use
    paidSecondsRef.current = paidSeconds;

    // Stop any existing timers
    stopTimerForChat();

    // Start session timer
    workerRef.current.postMessage({
      command: "start",
      type: "session",
      seconds: totalSeconds,
      initialValue: totalSeconds,
    });

    // Update Redux state
    dispatch(setSessionTimer(totalSeconds));
    dispatch(setFreeTimer(freeSeconds));
    dispatch(setPaidTimer(paidSeconds));

    // Configure and start timers with automatic transition
    if (freeSeconds > 0) {
      // Start free timer with paid timer as next
      workerRef.current.postMessage({
        command: "start",
        type: "free",
        seconds: freeSeconds,
        initialValue: freeSeconds,
        nextTimer: {
          type: "paid",
          seconds: paidSeconds,
          initialValue: paidSeconds,
        },
      });
    } else {
      // Start paid timer directly
      workerRef.current.postMessage({
        command: "start",
        type: "paid",
        seconds: paidSeconds,
        initialValue: paidSeconds,
      });
    }
  };

  const stopTimerForChat = () => {
    if (workerRef.current) {
      workerRef.current.postMessage({ command: "stop" });
    }
  };

  return { startTimerForChat, stopTimerForChat };
};

export default useHandleTimer;
