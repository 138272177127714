import { useCallback } from "react";
import {
  useUpdateAdvisorLiveStatusMutation,
  useUpdateRushStatusMutation,
  useUpdateCallStatusMutation,
} from "../app/api/advisor";

export const useAdvisorStatus = (advisorId) => {
  const [updateAdvisorLiveStatus] = useUpdateAdvisorLiveStatusMutation();
  const [updateRushStatus] = useUpdateRushStatusMutation();
  const [updateCallStatus] = useUpdateCallStatusMutation();

  const setOfflineStatus = useCallback(async () => {
    try {
      const statusData = {
        id: advisorId,
        live_status: 0,
        device_type: "web",
        user_id: advisorId,
        user_type: "advisor",
      };

      const formData = new FormData();
      Object.entries(statusData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await updateAdvisorLiveStatus(formData).unwrap();
      return { success: true, response };
    } catch (error) {
      console.error("Failed to update advisor live status:", error);
      throw error;
    }
  }, [advisorId, updateAdvisorLiveStatus]);

  const setOfflineFastStatus = useCallback(async () => {
    try {
      const statusData = {
        id: advisorId,
        status: 0,
        device_type: "web",
        user_id: advisorId,
        user_type: "advisor",
      };

      const formData = new FormData();
      Object.entries(statusData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await updateRushStatus(formData).unwrap();
      return { success: true, response };
    } catch (error) {
      console.error("Failed to update advisor fast status:", error);
      throw error;
    }
  }, [advisorId, updateRushStatus]);

  const setOfflineCallStatus = useCallback(async () => {
    try {
      const statusData = {
        id: advisorId,
        call_status: 0,
        device_type: "web",
        user_id: advisorId,
        user_type: "advisor",
      };

      const formData = new FormData();
      Object.entries(statusData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await updateCallStatus(formData).unwrap();
      return { success: true, response };
    } catch (error) {
      console.error("Failed to update advisor fast status:", error);
      throw error;
    }
  }, [advisorId, updateCallStatus]);

  return { setOfflineStatus, setOfflineFastStatus, setOfflineCallStatus };
};
