import { useCallback, useEffect, useRef } from "react";
import { ref, onValue, off, set, onDisconnect } from "firebase/database";
import { db } from "../utils/helper/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdvisorMessage,
  setChatBodyLiveFirebaseData,
  setFirebaseClientData,
  setFreeTimer,
  setIsInboxIsClicked,
  setIsReEngagementClicked,
  setPaidTimer,
  setSessionTimer,
  setUserChatsLog,
  setUserSessionData,
} from "../features/chat/chatSlice";
import {
  setClientResponseFromUpdateChatRoom,
  setIsCallingModalOpen,
  setIsClientResponseModalOpen,
  setIsInsertTemplateModalOpen,
  setIsLiveChatAIResponseData,
  setIsLiveChatAIResponseOpen,
  setIsLiveChatEnded,
  setIsWaitingForClientRecharge,
  setIsWaitingForMessageOpen,
  setIsCallAccepted,
} from "../features/modal/modalSlice";
import useHandleTimer from "./useHandleTimer";
import useHandleModalTimer from "./useModalTimer";
import useAllModalTimerHandler from "./useAllModalTimerHandler";
import { useCreateMessageMutation } from "../app/api/chat";
import { usePubNub } from "pubnub-react";
import usePubNubData from "./usePubnubData";

export const useFetchChatLogs = (advisorID) => {
  const pubnub = usePubNub();
  const { setChannels } = usePubNubData();
  const { startModalTimer, stopModalTimer } = useHandleModalTimer();
  const { startAllModalHandlerTimer, stoptAllModalHandlerTimer } =
    useAllModalTimerHandler();
  const [createMessage] = useCreateMessageMutation();
  const timerRunningRef = useRef(false); // To track if the timer is already running
  const paidTimerRef = useRef(0); // Ref to track the latest value of paidTimer
  const freeTimerRef = useRef(0); // Ref to track the latest value of freeTimer
  const userSessionDataRef = useRef(false); // Ref to track the latest value of userSessionData

  const dispatch = useDispatch();
  const { startTimerForChat, stopTimerForChat } = useHandleTimer();
  const {
    userSessionData,
    freeTimer,
    paidTimer,
    isInboxIsClicked,
    pubnubChannel,
  } = useSelector((state) => state.chat);
  useEffect(() => {
    paidTimerRef.current = paidTimer;
    freeTimerRef.current = freeTimer;
  }, [paidTimer, freeTimer]);

  const handleChatGptResponse = async (advisorID, userID) => {
    const formData = new FormData();
    formData.append("advisor_id", advisorID);
    formData.append("client_id", userID);
    formData.append("type", "chat-summary");
    formData.append("user_id", advisorID);
    formData.append("user_type", "advisor");
    try {
      const result = await createMessage(formData);
      if (result?.data?.result == "1") {
        dispatch(setIsLiveChatAIResponseData(result?.data?.message));
      }
    } catch (error) {}
  };

  const handleUnsubscribe = () => {
    pubnub.unsubscribe({
      channels: [`live_session_${userSessionData?.session_id}`],
    });
  };

  useEffect(() => {
    const chatLogsRef = ref(db, `live_chat_logs/a_${advisorID}`);

    const handleData = (snapshot) => {
      const data = snapshot.val();

      const lastTenEntries = Object.values(data).slice(-10);
      const lastEntry = lastTenEntries[lastTenEntries.length - 1];

      dispatch(setFirebaseClientData(lastEntry));
      dispatch(setUserSessionData(lastEntry));
      const precenceLiveChat = () => {
        const userKey = `a_${lastEntry?.advisor_id}`;
        const presenceRef = ref(
          db,
          `/live_chats/live_session_${lastEntry?.session_id}/users/${userKey}`
        );
        const onDisconnectRef = ref(db, `.info/connected`);
        onValue(onDisconnectRef, (snapshot) => {
          const isConnected = snapshot.val();
          if (isConnected) {
            set(presenceRef, true);
          }
          onDisconnect(presenceRef).set(false);
        });
      };

      if (lastEntry.status === "P") {
        userSessionDataRef.current = true;
        startModalTimer(lastEntry.created_at);
        dispatch(setIsCallingModalOpen(true));
        dispatch(setIsClientResponseModalOpen(false));
        precenceLiveChat();
        handleChatGptResponse(lastEntry?.advisor_id, lastEntry?.client_id);
        dispatch(setIsInsertTemplateModalOpen(false));
        dispatch(setIsLiveChatEnded(false));
      }

      if (userSessionDataRef.current === true) {
        if (lastEntry.status === "C") {
          dispatch(setIsCallingModalOpen(false));
          dispatch(setIsClientResponseModalOpen(true));
          dispatch(setIsWaitingForMessageOpen(false));
          dispatch(
            setClientResponseFromUpdateChatRoom("client has cancel the chat.")
          );
          dispatch(setUserSessionData({}));
          userSessionDataRef.current = false;
          stopModalTimer();
          stoptAllModalHandlerTimer("waitingForMessage");
        } else if (lastEntry.status === "A") {
          const newChannels = [
            `live_session_${lastEntry?.session_id}`,
            // Add any other channels you need here
          ];

          pubnub.subscribe({ channels: newChannels, withPresence: true });

          dispatch(
            setClientResponseFromUpdateChatRoom(
              "waiting for client to send his first message"
            )
          );

          dispatch(setIsCallAccepted(false));
          dispatch(setIsReEngagementClicked(false));
          dispatch(setIsInboxIsClicked(true));
          dispatch(setIsWaitingForMessageOpen(true));
          dispatch(setIsCallingModalOpen(false));
          stopModalTimer();
          startAllModalHandlerTimer("waitingForMessage", 70, () => {
            dispatch(setIsWaitingForMessageOpen(false));
            dispatch(setUserSessionData({}));
          });
        } else if (lastEntry.status === "R") {
          dispatch(setIsCallAccepted(false));
          dispatch(setIsCallingModalOpen(false));
          dispatch(setUserSessionData({}));
          stopModalTimer();
          userSessionDataRef.current = false;
        } else if (lastEntry.status === "E") {
          handleUnsubscribe();
          dispatch(setChatBodyLiveFirebaseData([]));
          stopTimerForChat();
          dispatch(setSessionTimer(0));
          dispatch(setFreeTimer(0));
          dispatch(setPaidTimer(0));
          dispatch(setIsLiveChatEnded(true));
          dispatch(setClientResponseFromUpdateChatRoom("live chat ended"));
          dispatch(setIsWaitingForMessageOpen(false));
          stoptAllModalHandlerTimer("waitingForMessage");
          stoptAllModalHandlerTimer("waitingForCreditsToRefil");
          dispatch(setIsWaitingForClientRecharge(false));
          dispatch(setIsWaitingForMessageOpen(false));
          dispatch(setIsInsertTemplateModalOpen(true));
          dispatch(setIsLiveChatAIResponseOpen(false));
          timerRunningRef.current = false; // Reset the flag as the timer has stopped
          userSessionDataRef.current = false;
        } else if (lastEntry.session_status === "D") {
          stoptAllModalHandlerTimer("waitingForMessage");
          dispatch(setIsWaitingForMessageOpen(false));
          dispatch(setUserSessionData({}));
        }
      }
      if (lastEntry.session_status === "S") {
        const starCountRef = ref(
          db,
          `live_chats/live_session_${lastEntry.session_id}`
        );

        onValue(starCountRef, (snapshot) => {
          const response = snapshot.val();
          const { free_seconds, paid_seconds, status } = response;

          const totalSeconds =
            +free_seconds +
            +paid_seconds +
            +freeTimerRef.current +
            +paidTimerRef.current;

          const freeSeconds = +free_seconds + +freeTimerRef.current;
          const paidSeconds = +paid_seconds + +paidTimerRef.current; // Use the ref value here

          const updatedMessage = response?.messages
            ? Object.values(response?.messages)
            : [];

          dispatch(setChatBodyLiveFirebaseData(updatedMessage));
          if (status === "S") {
            if (!timerRunningRef.current) {
              // stopTimerForChat();
              startTimerForChat(totalSeconds, freeSeconds, paidSeconds);
              timerRunningRef.current = true; // Set the flag to true indicating the timer is running
              dispatch(setIsWaitingForMessageOpen(false));
              dispatch(setIsWaitingForClientRecharge(false));
              stoptAllModalHandlerTimer("waitingForMessage");
              stoptAllModalHandlerTimer("waitingForCreditsToRefil");
            }
          } else if (status === "X") {
            if (timerRunningRef.current) {
              timerRunningRef.current = false;
            }
          }
        });
      }
    };

    onValue(chatLogsRef, handleData, {
      onlyOnce: false, // Subscribe continuously for real-time updates
    });

    return () => {
      off(chatLogsRef, "value", handleData); // Cleanup by unsubscribing when component unmounts
    };
  }, [advisorID]);

  return { userSessionData };
};
